.sticky-icon {
    position: fixed;
    bottom: 20px; /* Position the icon at the bottom */
    z-index: 1001; /* Higher z-index to ensure it appears above WhatsApp icon */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the icon horizontally */
}

.sticky-icon.left {
    left: 20px; /* Position for English */
}

.sticky-icon.right {
    right: 20px; /* Position for Arabic */
}

/* Make sure to reset margin for RTL */
.sticky-icon.right {
    margin-right: 0; /* Ensure no extra margin in RTL */
}

.icon {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; /* Consistent width */
    height: 50px; /* Consistent height */
}
  .dropdown {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: absolute;
    bottom: 60px; /* Position it above the icon */
    width: 150px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000; /* Ensure dropdown is below StickyIcon */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .dropdown div {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .dropdown div:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown img {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }