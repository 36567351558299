.whatsapp-icon {
    position: fixed;
    bottom: 80px; /* Position it below the StickyIcon */
    z-index: 1000; /* Lower z-index so it appears below the StickyIcon */
    cursor: pointer;
    width: 50px; /* Match size with StickyIcon */
    height: 50px; /* Match size with StickyIcon */
    border-radius: 50%;
    background-color: #333; /* Match background color */
    color: #fff; /* Match icon color */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Match font size */
}

.whatsapp-icon.left {
    left: 20px; /* Position for English */
}

.whatsapp-icon.right {
    right: 20px; /* Position for Arabic */
    margin-right: 10px;
}